import { Component, OnInit} from '@angular/core';
import { VersionCheckService } from './shared/services/version-check.service';
import { environment } from "../environments/environment";
import { ApiService } from './shared/services/api.service';
import { SplashScreenService } from './shared/services/splash-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{

  showLoader: boolean = false;

  constructor(
    private ApiService: ApiService,
    private versionCheckService: VersionCheckService,
    private SplashScreenService: SplashScreenService
  ) {
    ApiService.sailsApiRootUrl.next(environment.sailsApiRootUrl);
    ApiService.patientApiRootUrl.next(environment.patientApiRootUrl);
    ApiService.emrApiRootUrl.next(environment.emrApiRootUrl);
    ApiService.authApiRootUrl.next(environment.authApiRootUrl);
    ApiService.orderApiRootUrl.next(environment.orderApiRootUrl);
    ApiService.loginAuthorizationToken.next(environment.loginAuthorizationToken);
  }

  ngOnInit() {
    this.versionCheckService.initVersionCheck(environment.versionCheckURL)
  }
}
