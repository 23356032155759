import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { MatSnackBar } from '@angular/material/snack-bar';

interface notificationObj {
  state: string;
  class: string;
  message: string;
  duration: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private MatSnackBar: MatSnackBar
  ) { }

  info(message: string, duration = 5000) {
    this.MatSnackBar.open(message, '', {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'bg-info'
    })
  }
  error(message: string, duration = 5000) {
    this.MatSnackBar.open(message, '', {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'bg-danger'
    })
  }
  warning(message: string, duration = 5000) {
    this.MatSnackBar.open(message, '', {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'bg-warning'
    })
  }
  success(message: string, duration = 5000) {
    this.MatSnackBar.open(message, '', {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'bg-success'
    })
  }
}
