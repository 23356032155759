// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sailsApiRootUrl: 'https://web.staging.meddo.in',
  patientApiRootUrl: 'https://patient.staging.meddo.in',
  emrApiRootUrl: 'https://emr.staging.meddo.in',
  authApiRootUrl: 'https://auth.staging.meddo.in',
  orderApiRootUrl: 'https://order.staging.meddo.in',
  loginAuthorizationToken: 'Basic TFJMNlpDRXQ1QWc3d2hhYTpMd3BycmpkTHpNYzl6U3E0WFk0aldDejNoTldZTVZWNg==',
  sentryDsn: 'https://5319245670c5423f9d2b0131f4c67f40@sentry.local.meddo.in/11',
  googleToken: "127006431690-k74kp2m8b9779ii85ln96gj6qpdh203h.apps.googleusercontent.com",
  versionCheckURL: "https://backend.staging.meddo.in/version.json",
  websiteUrl: "https://staging.meddo.in"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
