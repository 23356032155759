import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injectable, ErrorHandler } from "@angular/core";
import { VersionCheckService } from "./shared/services/version-check.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SocialLoginModule } from "angularx-social-login";
import { getAuthServiceConfigs } from "src/socialloginConfig";
import { MaterialModule } from "./shared/material.module";
import { InterceptorService } from "./shared/services/interceptor.service";
import * as Sentry from "@sentry/browser";
import { environment } from "../environments/environment";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.production) {
      Sentry.init({
        dsn: environment.sentryDsn,
      });
      Sentry.captureException(error.originalError || error);
    }
    console.error(error);
  }
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SocialLoginModule,
    AppRoutingModule,
    MaterialModule,
  ],
  providers: [
    VersionCheckService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: "SocialAuthServiceConfig",
      useFactory: getAuthServiceConfigs,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
