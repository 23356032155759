import {
  SocialAuthServiceConfig,
  GoogleLoginProvider,
} from "angularx-social-login";
import { environment } from "src/environments/environment";

const config: SocialAuthServiceConfig = {
  providers: [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.googleToken),
    },
  ],
};

export function getAuthServiceConfigs() {
  return config;
}
