import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private ApiService: ApiService,
    private HttpClient: HttpClient,
    private Router: Router,
    private NotificationService: NotificationService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return Observable.create(obs => {
      const apiOptions = {
        headers: new Headers()
      };

      let productTypeUrl;
      let headers: any = {
        isAuthorized: 'yes'
      };

      productTypeUrl = this.ApiService.orderApiRootUrl.getValue() + '/api/v1/order/productTypes';
      this.HttpClient.get(productTypeUrl, { headers }).subscribe(response => {
        obs.next(true);
        obs.complete();
      }, error => {

        obs.next(false);
        obs.complete();
        this.NotificationService.error('Invalid login or something bad happened');
        this.Router.navigate(['/login', { return: state.url }]);
      })
    })
  }
}
