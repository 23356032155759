import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  sailsApiRootUrl: BehaviorSubject<string> = new BehaviorSubject('https://web.staging.meddo.in');
  patientApiRootUrl: BehaviorSubject<string> = new BehaviorSubject('https://patient.staging.meddo.in');
  emrApiRootUrl: BehaviorSubject<string> = new BehaviorSubject('https://emr.staging.meddo.in');
  orderApiRootUrl: BehaviorSubject<string> = new BehaviorSubject('https://order.staging.meddo.in');
  authApiRootUrl: BehaviorSubject<string> = new BehaviorSubject('https://auth.staging.meddo.in');
  loginAuthorizationToken: BehaviorSubject<string> = new BehaviorSubject('Basic TFJMNlpDRXQ1QWc3d2hhYTpMd3BycmpkTHpNYzl6U3E0WFk0aldDejNoTldZTVZWNg==');

  constructor(
    private HttpClient: HttpClient,
    private LocalStorage: LocalStorage
  ) { }

  approvalGETCall(endPoint: string, authorized: boolean, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.get(this.emrApiRootUrl.getValue() + endPoint, { headers, params })
  }

  approvalPOSTCall(endPoint: string, authorized: boolean, body, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.post(this.emrApiRootUrl.getValue() + endPoint, body, { headers, params })
  }

  authGETCall(endPoint: string, authorized: boolean, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.get(this.authApiRootUrl.getValue() + endPoint, { headers, params })
  }

  authPOSTCall(endPoint: string, authorized: boolean, body, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.post(this.authApiRootUrl.getValue() + endPoint, body, { headers, params })
  }

  authPATCHCall(endPoint: string, authorized: boolean, body, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.patch(this.authApiRootUrl.getValue() + endPoint, body, { headers, params })
  }

  authDELETECall(endPoint: string, authorized: boolean, params): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.delete(this.authApiRootUrl.getValue() + endPoint, { headers, params })
  }

  emrGETCall(endPoint: string, authorized: boolean, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.get(this.emrApiRootUrl.getValue() + endPoint, { headers, params })
  }

  emrPOSTCall(endPoint: string, authorized: boolean, body, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.post(this.emrApiRootUrl.getValue() + endPoint, body, { headers, params })
  }

  emrPATCHCall(endPoint: string, authorized: boolean, body, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.patch(this.emrApiRootUrl.getValue() + endPoint, body, { headers, params })
  }

  emrDELETECall(endPoint: string, authorized: boolean, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.delete(this.emrApiRootUrl.getValue() + endPoint, { headers, params })
  }

  orderGETCall(endPoint: string, authorized: boolean, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.get(this.orderApiRootUrl.getValue() + endPoint, { headers, params })
  }

  orderPOSTCall(endPoint: string, authorized: boolean, body, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.post(this.orderApiRootUrl.getValue() + endPoint, body, { headers, params })
  }

  orderPATCHCall(endPoint: string, authorized: boolean, body, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.patch(this.orderApiRootUrl.getValue() + endPoint, body, { headers, params })
  }

  sailsPOSTCall(endPoint: string, authorized: boolean, body, params?): Observable<any> {
    let headers: any = {};
    if (authorized) {
      headers.isAuthorized = 'yes';
    }
    return this.HttpClient.post(this.sailsApiRootUrl.getValue() + endPoint, body, { headers, params })
  }
}
