import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
  splashScreen;
  public player: AnimationPlayer;

  constructor(
    private AnimationBuilder: AnimationBuilder,
    @Inject(DOCUMENT) private DOCUMENT: any,
    private Router: Router
  ) {
    this.splashScreen = this.DOCUMENT.body.querySelector('#meddo-splash-screen');

    if (this.splashScreen) {
      const hideOnLoad = this.Router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          setTimeout(() => {
            this.hide();
            hideOnLoad.unsubscribe();
          }, 0);
        }
      }
      );
    }
  }

  hide() {
    this.player = this.AnimationBuilder.build([
      style({ opacity: '1' }),
      animate('1000ms ease', style({
        opacity: '0',
        zIndex: '-10'
      }))
    ]).create(this.splashScreen);

    setTimeout(() => {
      this.player.play();
    }, 0);
  }
}
