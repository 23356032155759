import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Observable } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(
    private LocalStorage: LocalStorage
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Apply the headers
    return this.LocalStorage.getItem('adminTokenMeddo').pipe(mergeMap((token: any, index: number) => {
      return Observable.create((observer) => {
        setTimeout(function () {
          if (!token) {
            observer.next(req);
          } else if (req.headers.get('isAuthorized') == 'yes') {
            observer.next(req.clone({
              setHeaders: {
                'Authorization': 'Bearer ' + token.access_token
              }
            }));
          } else {
            observer.next(req);
          }
          observer.complete();
        }, 300);
      }).pipe(mergeMap((req: HttpRequest<any>) => {
        return next.handle(req).pipe(
          tap(x => x, err => {
            console.error(`Error performing request, status code = ${err.status}`);
          })
        );
      }))
    }))
  }
}
